require('../libs/utils.exec.js');
import DP from '../scripts/dataProviders';

import { Clock, PerspectiveCamera, Scene, WebGLRenderer, AmbientLight, DirectionalLight, TextureLoader, PlaneGeometry, PlaneBufferGeometry, MeshBasicMaterial, MeshNormalMaterial, Mesh, Vector3 } from "three";
import { BloomEffect, EffectComposer, EffectPass, RenderPass} from "postprocessing";
import { WaterTexture } from '../effects/waterTexture';
import { WaterEffect } from '../effects/waterEffect';

var page1Blobs,isPortrait,waterEffect,camPageH,needResize,autoBusy,autoTimer,mainCanvas=gi('mainCanvas');
var blobAnims={},pauseBlobAnim=false,blobTime=0;

/*----Three JS setup----------------------------*/
var waterTexture=new WaterTexture({debug:false});

var renderer;
var camera=new PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 10000 );
var scene=new Scene();
scene.add(new AmbientLight(0x222222));

var light=new DirectionalLight(0xffffff);
light.position.set(1,1,1);
scene.add(light);

var composer;
var clock=new Clock();
var prevTime=0,currTime=0;
/*----END: Three JS setup----------------------------*/

var initComposer=function(){
	const renderPass = new RenderPass(scene, camera);
	waterEffect = new WaterEffect(waterTexture.texture);
	const waterPass = new EffectPass(camera, waterEffect);
	renderPass.renderToScreen = false;waterPass.renderToScreen = true;
	composer.addPass(renderPass);composer.addPass(waterPass);
},
initCam=function(reset){
    const canvas = renderer.domElement;
    camera.aspect = canvas.clientWidth/canvas.clientHeight;
    camPageH=isPortrait?170:85;
    camera.position.z=isPortrait?100:50;
    if(reset)camera.position.y = 0;
},
render=function(){
    if(needResize){needResize=false;
        const canvas = renderer.domElement;
        camera.aspect = canvas.clientWidth / canvas.clientHeight;
        camera.updateProjectionMatrix();
        renderer.render(scene, camera);
    }
    composer.render(clock.getDelta());
},
tick=function(){
	render();waterTexture.update();
    updateBlobAnims();
	requestAnimationFrame(tick);
},
onMouseMove=function(e){
    let pos=mpos(e);
    if(window.scrollY)pos.y=pos.y-window.scrollY;
    const point = {x: pos.x/ window.innerWidth, y: pos.y/ window.innerHeight};  
    waterTexture.addPoint(point);
},
getBlobDp=function(n){var dp=[];
    switch(n){
        case 1:dp=(isIOSAndroid&&isPortrait)?DP.p1_portrait:DP.p1;break;
        case 2:dp=(isIOSAndroid&&isPortrait)?DP.p2_portrait:DP.p2;break;
        case 3:dp=(isIOSAndroid&&isPortrait)?DP.p3_portrait:DP.p3;break;
        case 4:dp=(isIOSAndroid&&isPortrait)?DP.p4_portrait:DP.p4;break;
        case 5:dp=(isIOSAndroid&&isPortrait)?DP.p5_portrait:DP.p5;break;
        case 6:dp=(isIOSAndroid&&isPortrait)?DP.p6_portrait:DP.p6;break;
        case 7:dp=(isIOSAndroid&&isPortrait)?DP.p7_portrait:DP.p7;break;
    }
    return dp;
},
getBlobs=function(d){
    var offY=d.offY||0,dp=getBlobDp(d.n),preLoad=d.preLoad,onLoadCb=d.onLoadCb;
    dp.forEach(x=>{x.p.y-=offY});
    if(preLoad)loadInParallel(dp,onLoadCb);
    else {for(var i=0;i<dp.length;i++){dp[i].tex=new TextureLoader().load(dp[i].texImg);}}
    return dp;
},
addBlobPlanes=function(){
    createPlanes(scene,page1Blobs||getBlobs({n:1,offY:0}),'_p1');
    createPlanes(scene,getBlobs({n:2,offY:1*camPageH}),'_p2');
    createPlanes(scene,getBlobs({n:3,offY:2*camPageH}),'_p3');
    createPlanes(scene,getBlobs({n:4,offY:3*camPageH}),'_p4');
    createPlanes(scene,getBlobs({n:5,offY:4*camPageH}),'_p5');
    createPlanes(scene,getBlobs({n:6,offY:5*camPageH}),'_p6');
    createPlanes(scene,getBlobs({n:7,offY:6*camPageH}),'_p7');
    function createPlanes(scene,dp,idp){
        dp.forEach(d=>{
            let geometry = new PlaneBufferGeometry(5,5,1,1);
            let material = new MeshBasicMaterial({map:d.tex,transparent:true});
            let mesh = new Mesh(geometry, material);
            mesh.name=d.name+idp;
            if(d.p)mesh.position.set(d.p.x,d.p.y,d.p.z);
            if(d.s)mesh.scale.set(d.s.x,d.s.y,d.s.z);
            if(d.rZ)mesh.rotateZ(d.rZ);
            if(d.v!=undefined)mesh.visible=d.v?true:false;
            scene.add(mesh);
        });
    }
},
updateBlobAnims=function(){if(pauseBlobAnim)return;    
    prevTime=currTime;currTime=+new Date();
    var delta=(currTime-prevTime)/1000;
    for(var k in blobAnims){
        var bk=blobAnims[k],mesh=bk.mesh;
        bk.time=(bk.time+delta);
        bk.theta=(2*Math.PI*(bk.frequency)*bk.time)%(2*Math.PI);
        mesh.rotation.z=bk.swingAmplitude*Math.sin(bk.theta);
        mesh.position.x=bk.center.x+bk.revolveRadiusX*Math.sin(bk.clockwise*bk.theta);
        mesh.position.y=bk.center.y+bk.revolveRadiusY*Math.cos(bk.theta);
    }
}


var BG={
    scene:undefined,
	init:function(portrait,cb){
		isPortrait=portrait||false;
        renderer=new WebGLRenderer({ alpha:true, canvas: gi('mainCanvas'), antialias: false });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setClearColor(0x000000,0.0);
        composer=new EffectComposer(renderer);        
		getBlobs({n:1,preLoad:true,onLoadCb:function(r){
			page1Blobs=r;BG.scene=scene;
            initComposer();tick();initCam(true);addBlobPlanes();
	        aeh(window,'mousemove',onMouseMove);	        
	        if(cb)cb();
		}});
	},
    updateCameraOnScroll:function(body,sections){
        var cp=body.scrollHeight!=window.innerHeight?((window.scrollY/(body.scrollHeight-window.innerHeight))*(camPageH*(sections.length-1))):0;
        camera.position.y=-(cp);
    },
    autoAnimateBlobs:function(prevScrollY){
        if(!autoBusy){autoBusy=true;const point = {x: 0.25+Math.random()*0.5, y: 0.5};waterTexture.addPoint(point);let sign=Math.sign(window.scrollY-prevScrollY);
            autoTimer=setTimeout(function(){const point = {x: 0.25+Math.random()*0.5, y: 0.5+Math.random()*(0.01*sign)};waterTexture.addPoint(point);
                autoBusy=false;
            },1000);
        }
    },
    setBlobsToAnimate:function(page){
        pauseBlobAnim=true;blobAnims={};
        var n=page+1,dp=getBlobDp(n),idp='_p'+n;if(page==3)dp=[];
        for(var i=0;i<dp.length;i++){
            var name=dp[i].name+idp,mesh=scene.getObjectByName(name);
            blobAnims[name]={
                mesh:mesh,
                center:jp(js(dp[i].p)),
                swingAmplitude:(Math.random()>0.5?1:-1)*(0.05+Math.random(1)*0.05),
                revolveRadiusX:0.25+Math.random()*1.75,
                revolveRadiusY:0.25+Math.random()*1.75,
                clockwise:Math.random()>0.5?1:-1,
                time:0,theta:0,frequency:(0.25+Math.random()*0.75)/10
            };
        }
        pauseBlobAnim=false;
    },
	onWindowResize:function(portrait){
        isPortrait=portrait;needResize=true;        
        mainCanvas.style.width='100%';mainCanvas.style.height='100%';
        initCam();       
	},
	moveMesh:function(name,p,s){
		let mesh=scene.getObjectByName(name);
		mesh.position.set(p.x,p.y,p.z);
		mesh.scale.set(s.x,s.y,s.z);
	},
	printMesh:function(name){
		let mesh=scene.getObjectByName(name);
		console.log(mesh.position,mesh.scale);
	}
}

/*---------------Helper functions----------------------------*/
function loadInParallel(dp,cb){    
    var loadCount=0,onLoad=function(tex){loadCount++;if(loadCount==dp.length)cb(dp);};
    for(var i=0;i<dp.length;i++){dp[i].tex=new TextureLoader().load(dp[i].texImg,onLoad);}
}
function loadSerially(dp,cb){
    var loadedDp=[],loadNext=function(){if(dp.length){var d=dp[0];new TextureLoader().load(d.texImg,function(tex){d.tex=tex;loadedDp.push(d);dp.splice(0,1);loadNext();});}else if(cb)cb(loadedDp);};
    loadNext();
}
function v3(x,y,z){return {x:x,y:y,z:z};}
function v2(x,y){return {x:x,y:y};}
function resizeRendererToDisplaySize(renderer){
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
}

export default BG;